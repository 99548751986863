<template>
  <suspense>
    <main class="main_v8 home">
      <h3 @click="here">The Book of Knowledge for Your Business</h3>

      <transition-group v-for="(item, index) in items" :key="index">
        <!-- <router-link v-if="item.title != 'Favorites'" class="menu_item" :to="{ path: item.route }"
          :class="{ 'coming_soon': item.coming_soon }"
          :style="item.coming_soon ? {cursor: 'default'} : null"> -->
        <router-link class="menu_item" :to="{ path: item.route }"
          :class="{ 'coming_soon': item.coming_soon }"
          :style="item.coming_soon ? {cursor: 'default'} : null">
          <svg :style="[!item.coming_soon ? { backgroundImage: item.img } : { backgroundImage: item.img_disabled }]"></svg>
          <h3>{{ item.title }}</h3>
          <p class="tileSubtext">{{ item.desc }}</p>
          <span class="context_button" v-if="item.context" @click.stop.prevent="item.toggle = !item.toggle"></span>
          <span class="coming_soon_indicator tileSubtext" v-if="item.coming_soon">coming <br/> soon</span>

          <transition name="fade">
            <div v-if="item.context && item.toggle" @click.stop.prevent="item.toggle = !item.toggle" class="menu_shadow">
            </div>
          </transition>

          <div v-if="item.context && item.toggle" class="context">
            <ul>
<!--              <li>-->
<!--                <svg @click.stop.prevent="setStartToHome(); item.toggle = false; "-->
<!--                  :style="{ backgroundImage: contextHome }"></svg>-->
<!--                Set this page as start-->
<!--              </li>-->
              <!-- <li>
                  <svg :style="{backgroundImage: contextBookmark}"></svg>
                  Add to favorites</li> -->
              <!-- <li>
                  <svg :style="{backgroundImage: contextSos}"></svg>
                Create SOS</li> -->
            </ul>
          </div>
        </router-link>

        <router-link v-if="index === 0" class="menu_item create_now" @click.prevent="setDraft" :to="{name: 'Page', params: { id: 'new'}}">
          <svg></svg>
          <h3>Create Now</h3>
          <p class="tileSubtext">Video, Picture & Text based Tutorials</p>

          <transition name="fade">
            <div v-if="item.context && item.toggle" @click.stop.prevent="item.toggle = !item.toggle" class="menu_shadow">
            </div>
          </transition>
        </router-link>
        
        <div v-if="index === 0" :class="scanCamera ? 'menu_item menu_item_camera':'menu_item'" @click="clickQRCodeBlock">
          <transition-group v-if="!scanContent">
            <svg :style="[{ backgroundImage: 'url(img/home/qr_code_icon.svg)' }]"></svg>
            <h3>Scan & Go</h3>
            <p class="tileSubtext">{{ libCount }} Libraries</p>
          </transition-group>
          <transition v-else-if="scanCamera">
            <qrcode-stream @decode="onQRDecode"></qrcode-stream>
          </transition>
          <transition v-else-if="scanImage">
            <div class="qr_buttons">
<!--              <qrcode-drop-zone id="qrcode-decode-from-file-drag" @decode="onQRDecode" @click.prevent="uploadQRCode">Take a picture or Drag it here</qrcode-drop-zone>-->
<!--              <qrcode-capture id="qrcode-decode-from-file" :multiple="false" @decode="onQRDecode" accept="image/*" capture="environment"></qrcode-capture>-->
              <qrcode-drop-zone id="qrcode-decode-from-file-drag" @detect="onQRDetect" @click.prevent="uploadQRCode">Take a picture or Drag it here</qrcode-drop-zone>
              <qrcode-capture id="qrcode-decode-from-file" :multiple="false" @detect="onQRDetect" accept="image/*" capture="environment"></qrcode-capture>
              <div id="qrcode-decode-from-clipboard" @click.prevent="pasteImageFromClipboard">Paste from Clipboard</div>
              <div @click.prevent="scanImage = !scanImage; scanContent = !scanContent; buttonClick = true;">Cancel</div>
            </div>
          </transition>
          <transition v-else>
            <div class="qr_buttons">
<!--              <div @click.prevent="scanCamera = !scanCamera; buttonClick = true;">Use Camera</div>-->
              <div @click.prevent="scanImage = !scanImage; buttonClick = true;">Upload/Take a picture</div>
              <div @click.prevent="scanContent = !scanContent; buttonClick = true;">Cancel</div>
            </div>
          </transition>
        </div>
      </transition-group>

      <!-- <favorites></favorites> -->
    </main>
  </suspense>
</template>

<script>
// import { defineAsyncComponent } from 'vue';

// const favorites = defineAsyncComponent(
//   () => import('../favorites/favorites.vue'),
// );

import {ref} from "vue";
//import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'qrcode-reader-vue3';
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';

export default {
  name: 'home',
  inject: ['apiPath', 'imagesControl'],
  components: {
    // favorites,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
  },
  data() {
    return {
      contextHome: 'url(img/home/home_s.svg)',
      contextBookmark: 'url(img/home/bookmark_s.svg)',
      contextSos: 'url(img/home/sos_s.svg)',
      items: [],
      scanContent: ref(false),
      scanCamera: ref(false),
      scanImage: ref(false),
      buttonClick: ref(false),
      libCount: ref(0),
    };
  },
  methods: {
    here() {
      console.log(this.items);
    },
    setDraft() {
      localStorage.setItem('createDraft', '1');
    },
    getData() {
      fetch(`${this.apiPath}landingPageData?token=${this.$store.state.usertoken}`, {
        headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
        if (response.error === undefined) {
          this.items = response.items;
          this.libCount = response.machinekbCount; //future backend change
          this.items.splice(0, 1);
          // console.log(this.$store.state.usertoken);
          // if (this.$store.state.startpagename == 'Home') {
          //   this.items[0].desc = "Wave7 Home";
          // } else {
          //   this.items[0].desc = this.$store.state.startpagename;
          // }
          // this.items[0].route = this.$store.state.startpage;
        } else {
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
        }
        //this.items[0].route = '/select';
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    },
    onQRDecode(decodedString) {
      console.log('decoded: ', decodedString);
      window.location.href = decodedString;
    },
    onQRDetect(detectedArray) {
      if (detectedArray[0] !== null && detectedArray[0].rawValue !== undefined) {
        console.log('decoded: ', detectedArray[0].rawValue);
        window.location.href = detectedArray[0].rawValue;
      } else {
        alert('Wrong QR code. Please try again');
      }
    },
    uploadQRCode() {
      document.getElementById('qrcode-decode-from-file').click();
    },
    async pasteImageFromClipboard() {
      let imageObj = await this.imagesControl.getImageFromClipboard();
      if (imageObj) {
        const fileList = new DataTransfer();
        fileList.items.add(new File([imageObj.file], 'pastedImage.png', { type: imageObj.file.type }));
        document.getElementById('qrcode-decode-from-file').files = fileList.files;
        document.getElementById('qrcode-decode-from-file').dispatchEvent(new Event('change', { 'bubbles': true }));
      }
    },
    clickQRCodeBlock() {
      if (this.buttonClick) {
        this.buttonClick = false;
        return;
      }
      if (!this.scanContent) {
        this.scanContent = !this.scanContent;
        this.scanImage = !this.scanImage; //we're skipping the one-button screen
      } else if (this.scanCamera){
        this.scanCamera = !this.scanCamera;
      }
    },
    // setStartToHome() {
    //   this.$store.commit('savingPage');
    //
    //   this.$store.state.startpage = "/";
    //   this.$store.state.user.metadata.startpage = "/";
    //
    //
    //   this.$store.state.startpagename = "Home";
    //   this.$store.state.user.metadata.startpagename = "Home";
    //
    //   localStorage.setItem("bOpus_user", JSON.stringify(this.$store.state.user));
    //
    //   this.items[0].desc = "Wave7 Home";
    //   this.items[0].route = this.$store.state.startpage;
    //
    //   fetch('https://auth.wave7.cloud/auth/setmetadata', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type':'application/json',
    //       'Accept':'application/json'
    //     },
    //     body: JSON.stringify(this.$store.state.user.metadata)
    //   }).then((res) => res.json()).then((r) => {
    //     console.log("SET-METADATA-RESPONSE",r);
    //     this.$store.commit('pageSaved');
    //   }).catch((error) => {
    //     console.log('Looks like there was a problem: \n', error);
    //     this.$store.commit('savingError');
    //   });
    // },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.qr_buttons{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 54px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px) and (min-height: 920px) and (orientation: portrait) {
    padding: 54px;
  }
  @media screen and (max-height: 740px) and (min-height: 600px) and (max-width: 2560px) and (orientation: landscape) {
    padding: 25px;
  }
  @media screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 500px) and (orientation: landscape) {
    padding: 20px;
  }
  >div{
    width: 100%;
    box-sizing: border-box;
    // height: calc((100% / 3) - 11px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    // max-height: 45px;
    border-radius: 8px;
    border-width: 1px;
    max-width: 300px;
  }
  >div:not(:last-child){
    margin-bottom: 16px;
    background-color: #006C3E;
    color: #fff;
    transition: all .1s;
    @media screen and (max-width: 599px) and (orientation: portrait),
    screen and (max-height: 500px) and (orientation: landscape) {
      margin-bottom: 8px;
    }
    &:hover{
      border-color: rgba(0, 108, 62, 0.15);
      background-color: rgba(0, 108, 62, 0.15);
      color: #006C3E;
    }
    &:active{
      border-color: #004D2C;
      background-color: #004D2C;
      color: #fff;
    }
  }
  >div:last-child{
    border: 1px solid;
    border-color: #787878;
    color: #787878;
    background-color: #fff;
    min-height: 32px;
    &:hover{
      border-color: #006C3E;
      color: #006C3E;
      background-color: #fff;
    }
    &:active{
      border-color: #004D2C;
      background-color: #004D2C;
      color: #fff;
    }
  }
  #qrcode-decode-from-file {
    display: none;
  }
  #qrcode-decode-from-file-drag {
    padding: 10px 0;
    text-align: center;
  }
  #qrcode-decode-from-clipboard {
    padding: 10px 0;
    text-align: center;
  }
}
.main_v8.home{
  bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home {
  >h3{
    height: 50px;
    color: #787878;
    margin: 25px 0 5px;
    font-weight: 400;
    width: 100%;
    flex-shrink: 0;
    padding-left: 140px;
    background-image: url(../../public/img/bopus/bopus.svg);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    align-self: baseline;
    @media screen and (max-width: 599px) and (orientation: portrait),(max-height: 500px) and (orientation: landscape){
      padding-left: 130px;
      margin: 25px 0 5px;
    }
  }
}

.menu_item {
  display: inline-block;
  padding-top: 54px;
  padding-left: 54px;
  background: #FFFFFF;
  box-shadow: 0px 6px 20px 1px rgba(37, 37, 37, 0.04);
  border-radius: 16px;
  position: relative;
  box-sizing: border-box;
  text-decoration: none;
  width: calc(33.3% - 11px);
  height: calc(50% - 72px);
  // margin-bottom: 16px;
  min-height: 175px;
  @media screen and (max-width: 960px) {
  }
  @media screen and (min-height: 1080px) and (orientation: landscape) {
  }
  >svg {
    height: 64px;
    width: 64px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    margin-bottom: 90px;
  }
  >h3 {
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 0px;
    color: #252525;
    max-height: 92px;
    overflow: hidden;
    position: absolute;
    bottom: 84px;
  }
  >p {
    position: absolute;
    bottom: 50px;
    font-weight: 400;
    color: #787878;
    margin: 0;
    text-transform: capitalize;
  }
  .context_button {
    display: block;
    height: 32px;
    width: 32px;
    background-image: url(../../public/img/info_32.svg);
    position: absolute;
    right: 32px;
    top: 40px;
  }
  .coming_soon_indicator {
    position: absolute;
    text-align: center;
    right: 50px;
    top: 54px;
    background-color: rgba(0, 108, 62, 0.15);
    color: #006C3E;
    padding: 0 16px;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 500;
  }
}
.create_now{
  background-color: #006C3E;
  color: #fff;
  >p{
    color: #fff;
    @media screen and (max-height: 1024px) and (min-height: 600px) and (max-width: 1100px) and (min-width: 961px) and (orientation: landscape) {
      bottom: 40px !important;
    }
  }
  >h3{
    color: #fff;
    bottom: 94px;
    // @media screen and (max-height: 740px) and (min-height: 600px) and (max-width: 2560px) and (orientation: landscape) {}
    // @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px) and (min-height: 920px) and (orientation: portrait) {}
    // @media screen and (max-height: 1024px) and (min-height: 600px) and (max-width: 1366px) and (min-width: 961px) and (orientation: landscape) {}
    @media screen and (max-width: 599px) and (orientation: portrait),screen and (max-height: 599px) and (orientation: landscape) {
      bottom: 62px !important;}
  }
  svg{
    background-image: url(../../public/img/home/create.svg);
  }
}
.menu_item_camera {
  padding: 0;
  overflow: hidden;
}
.coming_soon {
  cursor: auto;

  >h3 {
    color: #bcbcbc;
  }

  >p {
    color: #bcbcbc;
  }
}

.menu_item:hover {
  cursor: pointer;
}

.context {
  position: absolute;
  top: 40px;
  right: 35px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 6px 20px 0px rgba(37, 37, 37, 0.5);
  border-radius: 8px;
  padding: 7px 16px;
  z-index: 3;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      margin: 10px 0;
      height: 24px;
      padding-left: 30px;
      position: relative;
      color: #787878;

      svg {
        background-repeat: no-repeat;
        height: 24px;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px) and (min-height: 920px) and (orientation: portrait) {
  .menu_item {
    padding-top: 54px;
    padding-left: 54px;
    width: calc(50% - 8px);
    height: calc(33.3% - 48px);
    margin-bottom: 16px;
    >h3 {
      position: absolute;
      bottom: 81px;
      max-height: 90px;
      margin-bottom: 0;
    }
    >p {
      position: absolute;
      bottom: 50px;
    }
    >svg {
      height: 60px;
      width: 60px;
    }
  }
  .menu_item_camera {
    padding: 0;
  }
}

@media screen and (max-height: 740px) and (min-height: 600px) and (max-width: 2560px) and (orientation: landscape) {
  .menu_item {
    padding-top: 25px;
    padding-left: 25px;
    // margin-bottom: 16px;
    >h3 {
      position: absolute;
      bottom: 56px;
      max-height: 93px;
      margin-bottom: 0;
      left: 30px;
    }
    >p {
      position: absolute;
      bottom: 25px;
      left: 30px;
    }
    >svg {
      height: 60px;
      width: 60px;
      left: 30px;
    }
  }
  .menu_item_camera {
    padding: 0;
  }
}

@media screen and (max-height: 1024px) and (min-height: 600px) and (max-width: 1366px) and (min-width: 961px) and (orientation: landscape) {
  .menu_item {
    padding-top: 54px;
    padding-left: 54px;
    margin-bottom: 16px;
    height: calc(50% - 68px);
    >h3 {
      position: absolute;
      bottom: 81px;
      max-height: 93px;
      margin-bottom: 0;
    }
    >p {
      position: absolute;
      bottom: 50px;
    }
    >svg {
      height: 60px;
      width: 60px;
    }
  }
  .menu_item_camera {
    padding: 0;
  }
}

@media screen and (max-width: 599px) and (orientation: portrait) {
  .context{
    right: -155px;
  }
  .home {
    h2{
      margin: 20px 0;
    }
  }
  .menu_item {
    height: calc(33.3% - 51px);
    width: calc(50% - 8px);
    padding-top: 20px;
    padding-left: 20px;
    // margin: 0 0 16px 0;

    >svg {
      height: 60px;
      width: 60px;
    }

    >h3 {
      font-weight: 500;
      padding-top: 32px;
      position: absolute;
      bottom: 44px;
      max-height: 73px;
      margin-bottom: 0;
    }

    >p {
      font-weight: 400;
      position: absolute;
      bottom: 20px;
    }

    .context_button {
      height: 24px;
      width: 24px;
      right: 20px;
      top: 20px;
      background-size: 100%;
    }

    .coming_soon_indicator {
      right: 12px;
      top: 20px;
      padding: 0 10px;
    }
  }
  .menu_item_camera {
    padding: 0;
  }
}

@media screen and (max-height: 599px) and (orientation: landscape) {
  .main_v8{
    bottom: 0px;
    top: 44px;
  }
  .home {
    justify-content: flex-start;
    padding: 0px 16px 16px;
  }
  .menu_item {
    height: calc(33.3% - 51px);
    flex: 0 0 25%;
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
    margin: 0 16px 16px 0;
    width: calc(50% - 8px);

    >svg {
      height: 60px;
      width: 60px;
    }

    >h3 {
      font-weight: 500;
      padding-top: 32px;
      position: absolute;
      bottom: 43px;
      max-height: 73px;
      margin-bottom: 0;
    }

    >p {
      font-weight: 400;
      position: absolute;
      bottom: 20px;
    }

    .context {
      right: auto;
      left: 155px;

      ul {
        li {
          width: 125px;
        }
      }
    }

    .context_button {
      height: 24px;
      width: 24px;
      right: 20px;
      top: 20px;
      background-size: 100%;
    }

    .coming_soon_indicator {
      right: 12px;
      top: 20px;
      padding: 0 10px;
    }
  }
  .menu_item_camera {
    padding: 0;
  }
}
</style>
